body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.d-md-down-none span {
  background-color: white !important;
}

.Sidebar_list li {
  padding: 5px 10px;
  border-bottom: 1px solid gray;
}

.Sidebar_list li:hover {
  background: #f3f3f3;
}
.p-sidebar-mask{
  z-index: 9 !important;
}
.a-link{ cursor: pointer }